import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { DangerouslySetInnerHtml, Layout } from 'layout';
import Banner from 'components/Banner';
import Video from 'components/Video';
import { SurveyPageComponentProps } from './models';
import './SurveyPageMain.scss';
import './SurveyPageRTL.scss';

const SurveyPage: FC<SurveyPageComponentProps> = ({
  data: {
    umbracoHome: { disclaimerDesktopImage, disclaimerMobileImage },
    umbracoSurvey: {
      seoMetaKeywords,
      seoMetaTitle,
      seoCanonicalUrl,
      seoMetaDescription,
      seoExternalHreflangs,
      defaultCompositions,
      banner,
      text,
      video,
      url,
    },
  },
}) => (
  <Layout
    {...{ defaultCompositions, disclaimerDesktopImage, disclaimerMobileImage }}
    seo={{
      seoMetaKeywords,
      seoMetaTitle,
      seoCanonicalUrl,
      seoMetaDescription,
      seoExternalHreflangs,
    }}
    className="survey-page"
  >
    {banner.map(({ properties: { title, ...restBannerProperties } }) => (
      <Banner
        url={url}
        key={title}
        title={title}
        {...{ ...restBannerProperties, image: restBannerProperties.localImage }}
      />
    ))}
    <div className="survey-page__container">
      <DangerouslySetInnerHtml className="survey-page__text" html={text} />
      {video.map(({ properties: videoProperties }, videoIndex) => (
        <Video
          key={`video-${videoProperties.imageAlt || videoIndex}`}
          className="survey-page__video"
          thumbnail={videoProperties.localImage}
          videoURL={videoProperties.videoLink || videoProperties.videoCMS?.fallbackUrl}
        />
      ))}
    </div>
  </Layout>
);

export const query = graphql`
  query SurveyPage($url: String = "", $lang: String) {
    umbracoSurvey: umbracoSurvey(url: { eq: $url }, lang: { eq: $lang }) {
      url
      seoMetaTitle
      seoCanonicalUrl
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      banner {
        properties {
          title
          variant
          localImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageAlt
          backgroundColour
        }
      }
      text
      video {
        properties {
          videoLink
          videoCMS {
            fallbackUrl
            url
          }
          localImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageAlt
        }
      }
    }
    umbracoHome(lang: { eq: $lang }) {
      disclaimerMobileImage {
        ...FragmentImageWithAlt
      }
      disclaimerDesktopImage {
        ...FragmentImageWithAlt
      }
    }
  }
`;

export default SurveyPage;
